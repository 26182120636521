var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "LBLUSEFLAG",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-lg-4" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "LBL0001243",
                  tableId: "variable",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12 col-lg-8" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0001244" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.saveEditable
                              ? _c("c-btn", {
                                  attrs: {
                                    isSubmit: _vm.isSave,
                                    url: _vm.saveUrl,
                                    param: _vm.variable,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveVariable,
                                    btnCallback: _vm.saveVariableCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              required: true,
                              label: "LBL0001245",
                              name: "variableName",
                            },
                            model: {
                              value: _vm.variable.variableName,
                              callback: function ($$v) {
                                _vm.$set(_vm.variable, "variableName", $$v)
                              },
                              expression: "variable.variableName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-plant", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              required: true,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.variable.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.variable, "plantCd", $$v)
                              },
                              expression: "variable.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              comboItems: _vm.useFlagItems,
                              label: "LBLUSEFLAG",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.variable.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.variable, "useFlag", $$v)
                              },
                              expression: "variable.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-tab", {
                            attrs: {
                              type: "tabcard",
                              align: "left",
                              tabItems: _vm.tabItems,
                              inlineLabel: true,
                              tabInCheck: true,
                              disabled: !_vm.saveEditable,
                            },
                            on: {
                              "update:tabItems": function ($event) {
                                _vm.tabItems = $event
                              },
                              "update:tab-items": function ($event) {
                                _vm.tabItems = $event
                              },
                              changeCheckbox: _vm.changeCheckbox,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (tab) {
                                  return [
                                    _c(tab.component, {
                                      tag: "component",
                                      attrs: {
                                        variable: _vm.variable,
                                        ramVariableGuidewordCd: tab.name,
                                        disabled: !tab.check,
                                      },
                                      on: {
                                        "update:variable": function ($event) {
                                          _vm.variable = $event
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }